<template>
  <div class="input-row-wrapper" @dblclick="handleEdit">
    <el-input
      v-if="isEdit"
      v-model="currentValue"
      placeholder="请输入"
      size="small"
      ref="inputRow"
      @blur="onDone"
      @keyup.enter.native="onDone"
    ></el-input>
    <span v-else>{{ value ? value : "[暂无数据]" }}</span>
  </div>
</template>

<script>
export default {
  name: "InputRow",
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      currentValue: null,
      isEdit: false,
    };
  },
  watch: {
    value: {
      handler(val) {
        this.currentValue = val;
      },
      immediate: true,
    },
  },
  methods: {
    handleEdit() {
      this.isEdit = true;
      this.$nextTick(() => {
        if (this.$refs["inputRow"]) {
          this.$refs["inputRow"].focus();
        }
      });
    },
    onDone(e) {
      this.$emit("input", e.target.value);
      this.isEdit = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-row-wrapper {
  display: inline-block;
  width: auto;
  height: 100%;
  cursor: pointer;
}
</style>
